import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppealStatusView from '@/views/AppealStatusView.vue'
import HomePageView from '@/views/HomePageView.vue'
import SubmitAppealView from '@/views/SubmitAppealView.vue'
import { useMainStore } from '@/store/main'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePageView,

  },
  {
    path: '/appeal-status',
    name: 'AppealStatus',
    component: AppealStatusView,
  },
  {
    path: '/submit-appeal',
    name: 'SubmitAppeal',
    component: SubmitAppealView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to) => {
  const store = useMainStore()

  return (!store.isSignedIn && to.name !== 'HomePage')
    ? { name: 'HomePage' }
    : true
})

export default router
