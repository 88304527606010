<script setup lang="ts">
import api from '@/api/client'
import { useMainStore } from '@/store/main'
import imageHeaders from '@/assets/img/lol-header-sprite.png'

const styles = {
  nav: {
    background: `transparent url(${imageHeaders}) 50% -50px / cover no-repeat`,
  },
  navCorner: {
    background: `transparent url(${imageHeaders}) -100px -450px no-repeat`,
  },
}

const store = useMainStore()

const logout = async () => {
  await api.logout()
  window.location.replace('/')
}
</script>

<template>
  <nav :style="styles.nav">
    <div class="nav-corner-top-left" :style="styles.navCorner"></div>

    <ul v-if="store.isSignedIn">
      <li>
        <router-link :to="{ name: 'HomePage' }">Home</router-link>
      </li>
      <li v-if="store.showPageSubmitAppeal">
        <router-link :to="{ name: 'SubmitAppeal' }">Submit Appeal</router-link>
      </li>
      <li v-if="store.showPageAppealStatus">
        <router-link :to="{ name: 'AppealStatus' }">Appeal Status</router-link>
      </li>
      <li>
        <a href="#" @click.prevent="logout">Logout</a>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
nav {
  position: relative;
  height: 122px;
  width: 1100px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.nav-corner-top-left {
  position: absolute;
  z-index: 10;
  width: 45px;
  height: 350px;
  top: 42px;
  left: 28px;
}
ul {
  display: flex;
  gap: 0.5rem;

  width: 65%;
  margin-right: 7%;
  margin-left: auto;
  height: 37%;
  margin-bottom: 2%;

  list-style: none;

  li {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 0.25rem;

    flex-grow: 1;
    border-bottom: 1px solid transparent;
    transition-duration: 0.3s;
  }

  a, button {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    text-decoration: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: #E66C2A;
    }

    &.router-link-active {
      background-color: rgba(0, 0, 0, 0.3);
      color: #E66C2A;
    }

    &.router-link-active {
      border-color: #E66C2A;
    }
  }
}
</style>
