<script setup lang="ts">
import imageFooter from '@/assets/img/lol-footer-sprite.png'

const styles = {
  footer: {
    background: `transparent url(${imageFooter}) 50% 0px no-repeat`,
  },
}

</script>

<template>
  <footer :style="styles.footer"></footer>
</template>

<style scoped lang="scss">
footer {
  width: 1012px;
  //height: 374px;
  height: 10px;
  //padding: 2rem;
  margin-top: -6px;
}
</style>
