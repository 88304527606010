<script setup lang="ts">
import { ref } from 'vue'
import { useMainStore } from '@/store/main'
import sprites from '@/assets/img/sprites.png'
import CaseInformation from '@/components/CaseInformationComponent'

const store = useMainStore()
const oauthUrl = ref((process.env.SERVER_URL ?? '') + '/oauth/discord')

const spriteStyle = ref({
  backgroundImage: `url(${sprites})`,
})
</script>

<template>
  <div class="page">
    <h1>Ban Appeals</h1>
    <div class="divider" :style="spriteStyle" />

    <div class="btn-group">
      <a v-if="!store.isSignedIn"  :href="oauthUrl" class="btn" :style="spriteStyle"><img src="/discord-mark.svg" alt="Discord Logo" class="discord-logo" />Login</a>

      <CaseInformation />

      <router-link v-if="store.showPageSubmitAppeal"  :to="{ name: 'SubmitAppeal' }" class="btn" :style="spriteStyle">Submit an Appeal</router-link>
      <router-link v-if="store.showPageAppealStatus" :to="{ name: 'AppealStatus' }" class="btn" :style="spriteStyle">Status</router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  background-color: transparent;
  box-shadow: none;
}

h1 {
  font-size: 4rem;
  margin: 0;
}

.btn-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    background-position: 0 0;
    background-repeat: no-repeat;

    width: 370px;
    height: 51px;

    color: white; //#742005;
    font: 18px 'Times New Roman';
    letter-spacing: 0.10rem;
    font-size: 1.75rem;
    text-decoration: none;
    text-shadow: 1px 1px #742005; //#FFC957;

    &:hover {
      background-position: 0 -57px;
      color: #742005;
      text-shadow: 1px 1px #FFC957;
    }
  }

  .discord-logo {
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 0.30rem;

    &:hover {
      background-position: 0 -57px;
      color: #742005;
      text-shadow: 1px 1px #FFC957;
    }
  }
}
</style>
