<script setup lang="ts">
import { computed, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import imageContentBorder from '@/assets/img/content_bg.png'
import { Appeal, FormQuestion, TableHeader, useMainStore } from '@/store/main'
import AppealsFormComponent from '@/components/AppealsFormComponent'
import imageContentBackground from '@/assets/img/background.jpg'
import imageFooterSprites from '@/assets/img/lol-footer-sprite.png'

const store = useMainStore()
const styles = {
  tableDetails: {
    background: `transparent url(${imageContentBorder}) center center / contain repeat-y`,
  },
  tableDetailsCloseButton: {
    backgroundImage: `url(${imageFooterSprites})`,
  },
}

const headers = ref<TableHeader[]>([
  { name: 'id', displayName: 'Appeal #' },
  { name: 'case_id', displayName: 'Case #' },
  { name: 'case_ban_at', displayName: 'Ban Date' },
  { name: 'case_ban', displayName: 'Ban Reason' },
  { name: 'status', displayName: 'Appeal Status' },
  { name: 'status_reason', displayName: 'Explanation' },
  { name: '_details', displayName: '' },
])

const selectedItem = ref<Appeal | null>(null)
const selectedItemQuestions = computed<FormQuestion[]>((): FormQuestion[] => {
  if (!selectedItem.value) return []
  return selectedItem.value.questions.map(x => x.question) as FormQuestion[]
})
const selectedItemValues = computed<Record<string, any>>(() => {
  if (!selectedItem.value) return {}
  return selectedItem.value.questions.reduce((result, x) => {
    if (x.question?.type === 'checkbox') {
      result[x.question?.id ?? '_'] = x.message === 'true'
    } else {
      result[x.question?.id ?? '_'] = x.message
    }

    return result
  }, {} as Record<string, any>)
})

const sortBy = ref<any>(headers.value[0]?.sortable ? headers.value[0].name : '')
const sortAsc = ref(true)

const itemsFormatted = computed<any[]>(() => {
  let result = store.appeals
  if (sortBy.value && sortAsc.value) result = result.sort((a, b) => (a[sortBy.value] as any) - (b[sortBy.value] as any))
  if (sortBy.value && !sortAsc.value) result = result.sort((a, b) => (b[sortBy.value] as any) - (a[sortBy.value] as any))

  return result
})

const toggleSort = (name: string): void => {
  if (!headers.value.find(x => x.name === name)?.sortable) return
  if (sortBy.value === name) {
    if (!sortAsc.value) {
      sortBy.value = headers.value[0]?.sortable ? headers.value[0].name : ''
    }

    sortAsc.value = !sortAsc.value
  } else {
    sortBy.value = name
    sortAsc.value = true
  }
}

</script>

<template>
  <div class="table-body">
    <table class="pure-table pure-table-horizontal">
      <thead>
      <tr>
        <th
          v-for="(header, i) in headers"
          :key="header.name"
          :class="{ clickable: header.sortable }"
          @click="toggleSort(header.name)"
        >
          <template v-if="'displayName' in header">
            {{ header.displayName }}
          </template>
          <template v-else>
            {{ header.name }}
          </template>

          <FontAwesomeIcon
            v-if="sortBy === header.name || (!sortBy && i === 0 && headers[i].sortable)"
            :icon="sortAsc ? 'chevron-down' : 'chevron-up'"
          />
        </th>
      </tr>
      </thead>

      <tbody>
      <tr
        v-for="item in itemsFormatted"
        :key="item.id"
      >
        <td
          v-for="(col, index) in headers"
          :key="col.name"
        >
          <template v-if="col.name === 'case_ban'">
            {{ item.case.reason }}
          </template>
          <template v-else-if="col.name === 'case_ban_at'">
            {{ item.case.created_at_friendly }}
          </template>
          <template v-else-if="col.name === '_details'">
            <button @click="selectedItem = item">Details</button>
          </template>
          <slot
            v-else
            :name="`column-${index}`"
            :item="item"
            :value="item[col.name]"
          >
            {{ item[col.name] }}
          </slot>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <teleport to="body">
    <div v-if="!!selectedItem" class="modal-background-overlay" @click="selectedItem = null"></div>
  </teleport>

  <teleport to=".main-content-inner">
    <div v-if="!!selectedItem" class="modal-wrapper">
      <aside class="modal" :style="styles.tableDetails">
        <div class="modal-header">
          <h3>Details</h3>
        </div>
        <div class="modal-body">
          <dl>
            <dt>Originally Banned At</dt>
            <dd>{{ selectedItem.case?.created_at_friendly }}</dd>

            <dt>Original Ban Reason</dt>
            <dd>{{ selectedItem.case?.reason }}</dd>

            <dt>Appeal Submitted At</dt>
            <dd>{{ selectedItem.created_at_friendly }}</dd>

            <dt>Appeal Outcome</dt>
            <dd>
              <span>{{ selectedItem.status }}<template v-if="selectedItem.status_reason">:</template></span>
              <span v-if="selectedItem.status_reason">{{ selectedItem.status_reason }}</span>
            </dd>
          </dl>

          <AppealsFormComponent :questions="selectedItemQuestions" :values="selectedItemValues"></AppealsFormComponent>
        </div>
        <div class="modal-footer">
          <button class="pure-button" :style="styles.tableDetailsCloseButton" @click="selectedItem = null">Close</button>
        </div>
      </aside>
    </div>
  </teleport>
</template>

<style scoped lang="scss">
th {
  user-select: none;
  min-width: 80px;

  svg {
    margin-left: 0.25em;
  }
}

th.clickable {
  cursor: pointer;
}

.modal-background-overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-wrapper {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background-color: white;
    min-width: 50%;
    min-height: 50%;
    max-width: 90%;
    max-height: 90%;

    display: flex;
    flex-direction: column;
  }

  .modal-header, .modal-body, .modal-footer {
    padding: 0.5rem 2rem;
  }

  .modal-header {
    min-height: 30px;
    border-bottom: 1px solid black;
    flex-shrink: 0;

    h3 {
      margin: 0;
    }
  }

  .modal-body {
    min-height: 100px;
    flex-grow: 1;
  }

  .modal-footer {
    border-top: 1px solid black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      background-position: -163px -801px;
      background-repeat: no-repeat;

      border: none;
      width: 248px;
      height: 82px;

      color: #ECC873;
      font: 18px 'Times New Roman';
      letter-spacing: 0.10rem;
      font-size: 1.75rem;
      text-decoration: none;
      text-shadow: 1px 1px #742005; //#FFC957;

      &:hover {
        background-position: -501px -801px;
        color: #E6D7AB;
      }
    }
  }
}

dd span {
  margin-right: 0.5em;
}
</style>

<style lang="scss">
.modal .formkit-outer[data-disabled] {
  opacity: 1;
}
</style>
