// formkit.config.ts
import { defaultConfig } from '@formkit/vue'
import { genesisIcons } from '@formkit/icons'
import '@formkit/themes/genesis'

export default defaultConfig({
  icons: {
    ...genesisIcons,
  },
})
