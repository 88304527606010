<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { AppealCase, useMainStore } from '@/store/main'
const store = useMainStore()

const props = defineProps<{
  appealCase?: AppealCase
}>()

const statusMessage = computed<string>(() => {
  return store.showPageSubmitAppeal
    ? 'You are currently banned from the server. Submit an appeal if you\'d like to change that.'
    : 'You are currently banned from the server and have already submitted an appeal.'
})

const hasActivePunishments = computed(() => {
  return !!props.appealCase || store.hasActivePunishments
})

const activePunishment = computed(() => {
  return props.appealCase || store.user?.punishments[0]
})
</script>

<template>
  <div v-if="hasActivePunishments">
    {{ statusMessage }}

    <dl>
      <dt>Banned At:</dt>
      <dd>{{ activePunishment?.created_at_friendly }}</dd>

      <dt>Banned Reason:</dt>
      <dd>{{ activePunishment?.reason }}</dd>
    </dl>
  </div>
</template>

<style scoped lang="scss">
div {
  user-select: none;

  max-width: 50ch;
}
</style>
