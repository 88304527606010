<script setup lang="ts">
import { onMounted, ref } from 'vue'
import sprites from '@/assets/img/sprites.png'
import AppealsFormComponent from '@/components/AppealsFormComponent.vue'
import { FormQuestion } from '@/store/main'
import api from '@/api/client'
import CaseInformation from '@/components/CaseInformationComponent'

const questions = ref<FormQuestion[]>([])
const questionsLoaded = ref(false)

const spriteStyle = ref({
  backgroundImage: `url(${sprites})`,
})

onMounted(async () => {
  const response = await api.service('questions').find()

  questions.value = response.data
  questionsLoaded.value = true
})

</script>

<template>
  <div class="page">
    <h1 hidden>Submit Appeal</h1>
    <div class="divider" :style="spriteStyle" />
    <CaseInformation />

    <h2 v-if="!questionsLoaded">Loading questions, please wait...</h2>

    <AppealsFormComponent v-else :questions="questions"></AppealsFormComponent>
  </div>
</template>

<style scoped lang="scss">
h1 {
  font-size: 4rem;
  margin: 0;
}
</style>
