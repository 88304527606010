/* eslint-disable camelcase */

import { defineStore } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import api from '@/api/client'

export type TableHeader = {
  name: string,
  displayName: string
  sortable?: boolean
}

export type AppealCase = {
  guild_id: string,
  case_id: number,
  target_id: string,
  issuer_id: string,
  revoker_id?: string,
  action_type: string,
  created_at?: string,
  created_at_friendly?: string,
  updated_at?: string,
  expires_at?: string,
  expires_at_friendly?: string,
  revoked_at?: string,
  direct_message_id?: string,
  log_message_id?: string,
  archive_id?: string,
  reason?: string,
  revoke_reason?: string,
  expired: boolean,
  automatic: boolean,
  manual: boolean,
}

export type FormQuestion = {
  id: number,
  type: string,
  guild_id: string,
  message: string,
  active: boolean,
  created_at: Date,
  updated_at: Date,
}

export type AppealQuestion = {
  id: number,
  appeal_id: number,
  question_id: number,
  message: string,
  created_at: Date,
  updated_at: Date,
  question?: FormQuestion,
}

export type Appeal = {
  id: number,
  user_id: string,
  guild_id: string,
  case_id: number,
  status: string,
  status_reason: string,
  issuer_id: number,
  created_at: Date,
  created_at_friendly: string,
  updated_at: Date,
  questions: AppealQuestion[],
  case?: AppealCase,
}

export type Guild = {
  id: string,
  name: string,
}

export type User = {
  id: string,
  avatar: string,
  username: string,
  guilds: string[],
  punishments: AppealCase[],
}

export const useMainStore = defineStore('main', () => {
  const authenticating = ref(true)
  const checking_appeals = ref(true)
  const user = ref<User | null>()
  const appeals = ref<Appeal[]>([])

  const isSignedIn = computed<boolean>(() => !!user.value && !checking_appeals.value)

  const hasActivePunishments = computed<boolean>(() => {
    return (user.value?.punishments?.length ?? 0) > 0
  })

  const showPageSubmitAppeal = computed<boolean>(() => {
    if (!isSignedIn.value) return false

    const activeCases = user.value?.punishments ?? []
    if (activeCases.length === 0) return false
    if (appeals.value.length === 0) return true

    return activeCases.filter(x => !appeals.value.some(y => y.case_id === x.case_id)).length > 0
  })

  const showPageAppealStatus = computed<boolean>(() => {
    return isSignedIn.value && appeals.value.length > 0
  })

  api.on('login', async (authResult) => {
    user.value = authResult.user
    appeals.value = (await api.service('appeals').find({})).data
    checking_appeals.value = false

    await new Promise(resolve => setTimeout(resolve, 1000))
    if (!(showPageAppealStatus.value || showPageSubmitAppeal.value)) {
      window.location.replace('https://discord.gg/leagueoflegends')
    }
  })

  api.on('logout', () => {
    user.value = null
  })

  onMounted(async () => {
    try {
      if (await api.authentication.getAccessToken()) {
        await api.authenticate()
        authenticating.value = false
        return
      }
    } catch (err: any) {
      // Not actually an error, don't worry about it
    }

    try {
      await new Promise(resolve => setTimeout(resolve, 100))
      if (window.location.hash?.startsWith(('#access_token='))) {
        const accessToken = window.location.hash.slice(window.location.hash.indexOf('=') + 1)
        window.location.hash = ''
        await api.authenticate({
          strategy: 'jwt',
          accessToken,
        })

        authenticating.value = false
      }
    } catch (err: any) {
    }

    authenticating.value = false
  })

  return {
    authenticating,
    user,
    isSignedIn,
    hasActivePunishments,
    showPageSubmitAppeal,
    showPageAppealStatus,
    appeals,
  }
})
