<script lang="ts" setup>
import Footer from '@/components/layout/FooterComponent.vue'
import Navbar from '@/components/layout/NavbarComponent.vue'
import imageContentBackground from '@/assets/img/background.jpg'
import imageContentBorder from '@/assets/img/content_bg.png'
import { useMainStore } from '@/store/main'

const store = useMainStore()
const styles = {
  main: {
    background: `transparent url(${imageContentBorder}) center center / contain repeat-y`,
  },
  mainContent: {
    background: `transparent url(${imageContentBackground}) center center / cover no-repeat`,
  },
}

</script>

<template>
  <template v-if="!store.authenticating">
    <Navbar></Navbar>
    <main :style="styles.main">
      <div class="main-content-wrapper" :style="styles.mainContent">
        <div class="main-content-inner">
          <router-view/>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </template>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  font-family: sans-serif;

  nav, footer {
    flex-shrink: 0;
  }

  h1, h2, h3, h4, h5, h6, p, td, th, label {
    &::selection {
      color: #E66C2A;
      background-color: white;
    }
  }
}

main {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  width: 1000px;

  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.main-content-wrapper {
  width: 100%;
}

main, footer {
  position: relative;
}

.main-content-inner {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  content: ' ';
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.3);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &::-webkit-scrollbar {
    //display: none;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &::-webkit-scrollbar-thumb {
    //background-color: #2F70B9;
    background-color: #420303;
  }
}

main {
  width: 1020px;
  padding: 0 8px;
  max-width: 100%;
  max-height: 1000px;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //height: 100%;
  width: 100%;
  //max-width: 60ch;
  padding: 1rem 2rem;
  color: white;
  //background-color: #860B0088;
  //border-radius: 8px;
  //box-shadow: 2px 3px 20px 2px rgba(0, 0, 0, 0.2);

  //@media (min-width: 1200px) {
  //  margin-left: 5%;
  //}
}

.divider {
  width: 400px;
  background-color: transparent;
  border: none;
  background-position: 0 -117px;
  background-repeat: no-repeat;
  height: 13px;
  margin: 1rem 0;
}

dl {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5em 1em;

  dt {
    font-weight: bold;
    text-align: right;
  }

  dd {
    margin: 0;
  }
}
</style>
