<script setup lang="ts">
import { defineProps } from 'vue'
import router from '@/router'
import api from '@/api/client'
import { FormQuestion, useMainStore } from '@/store/main'
import { paramsForServer } from 'feathers-hooks-common'

const store = useMainStore()
const props = defineProps<{
  questions: FormQuestion[],
  values?: Record<string, any>,
}>()

const handleSubmit = async (fields: Record<any, any>): Promise<any> => {
  try {
    const questions: any[] = Object.entries(fields).map(([key, value]) => ({
      question_id: parseInt(key),
      message: value.toString(),
    }))

    const result = await api.service('appeals').create({
      case_id: store.user?.punishments[0].case_id ?? -1,
      guild_id: store.user?.punishments[0].guild_id,
    }, paramsForServer({ questions } as any))

    alert('Your appeal has been submitted, it will be reviewed by the mod team soon.')
    await router.push({ name: 'AppealStatus' })
  } catch (err: any) {
    alert('There was an error submitting your appeal, please try again later')
    console.error(err)
  }
}

const validationRules: Record<string, any[][]> = {
  text: [
    ['required', 'trim'],
    ['length', 1, 1000],
  ],
  textarea: [
    ['required', 'trim'],
    ['length', 1, 1000],
  ],
  checkbox: [
    ['accepted'],
  ],
}

</script>

<template>
  <div class="form-wrapper">
    <FormKit
      v-if="!props.values"
      type="form"
      @submit="handleSubmit"
    >
      <FormKit
        v-for="question in props.questions"
        :key="question.id"
        :id="question.id"
        :name="question.id"
        :type="question.type"
        :label="question.message"
        :validation="validationRules[question.type]"
        validation-visibility="live"
      ></FormKit>
    </FormKit>

    <FormKit
      v-else
      type="form"
      @submit="handleSubmit"
      :value="values"
      :actions="false"
      disabled
    >
      <FormKit
        v-for="question in props.questions"
        :key="question.id"
        :id="question.id"
        :name="question.id"
        :type="question.type"
        :label="question.message"
      ></FormKit>
    </FormKit>
  </div>
</template>

<style scoped lang="scss">
.form-wrapper {
  --fk-bg-input: #FFFFFF;
  --fk-max-width-input: 100%;

  //max-height: 100%;
  width: 100%;
  padding: 2rem 1rem;
  //overflow-y: auto;
}
</style>
