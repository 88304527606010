import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import type { Plugin } from 'vue'

const plugin: Plugin = {
  install (): void {
    library.add(faBars)
    library.add(faChevronDown)
    library.add(faChevronLeft)
    library.add(faChevronRight)
    library.add(faChevronUp)
    library.add(faSpinner)
  },
}

export default plugin
